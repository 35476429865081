var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.opened
    ? _c(
        "modal",
        {
          staticClass: "modal--content",
          attrs: {
            loading: _vm.loading,
            "close-button-inside": true,
            "close-button-position": _vm.closeButtonPosition,
          },
          on: { close: _vm.close },
        },
        [
          _c("div", {
            ref: "content",
            staticClass: "modal__inner-content",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }