export default class Dialogue {
  constructor (element) {
    // Add color to unique speaker names
    const speakerNodes = Array.from(element.querySelectorAll('.js-dialogue-speaker'))
    const speakers = speakerNodes.map(speakerElement => speakerElement.textContent).filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    const classes = ['text-green-dark', 'text-orange-dark', 'text-blue-dark', 'text-red-dark', 'text-purple-dark']

    speakerNodes.forEach(speakerElement => {
      const index = speakers.indexOf(speakerElement.textContent)
      if (index >= 0) {
        speakerElement.classList.add('speaker-' + index, classes[index % classes.length])
      }
    })
  }
}
