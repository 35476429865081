import Routing from './routing'

export default {
  methods: {
    getPreviousSibling (elem, selector) {
      // get previous sibling
      let previousSibling = elem.previousElementSibling

      // when no selector, return first sibling
      if (!selector) return previousSibling

      // if sibling matches the selector, use it
      // if it doesn't, go to the next previous sibling and continue
      while (previousSibling) {
        if (previousSibling.matches(selector)) return previousSibling
        previousSibling = previousSibling.previousElementSibling
      }
    },

    getNextSibling (elem, selector) {
      // get next sibling
      let nextSibling = elem.nextElementSibling
      if (!selector) return nextSibling
      while (nextSibling) {
        if (nextSibling.matches(selector)) return nextSibling
        nextSibling = nextSibling.nextElementSibling
      }
    },

    getUrl (condition, paramIf, paramElse, store) {
      return Routing.generate(
        condition ? paramIf : paramElse,
        {
          streamCode: msp.streamCode,
          groupId: store.groupId,
          contentPath: store.contentPath
        }
      )
    }
  }
}
