var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "fill-current inline-block align-middle",
    class: _vm.classes,
    attrs: { "aria-hidden": "true" },
    domProps: { innerHTML: _vm._s(_vm.iconContent) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }