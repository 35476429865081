'use strict'

import tippy from 'tippy.js'
import Equation from './Equation'
import TooltipStyle from './tooltip/TooltipStyle'

export default class Tooltip {
  constructor (element, options) {
    let content = element.querySelector('.js-tooltip-content')
    if (!content) {
      throw new Error('Tooltip is missing content')
    } else {
      content = content.innerHTML
    }

    const placement = element.dataset.placement ?? options.placement ?? 'right'
    const toolTipStyle = TooltipStyle.createDefault(options.redesign)

    let modifiers = toolTipStyle.getModifiers()
    content = toolTipStyle.wrapContent(content)

    this.tooltip = tippy(element, {
      content: content,
      allowHTML: true,
      trigger: element.classList.contains('js-tooltip--hover') ? 'mouseenter' : 'click',
      placement: placement,
      interactive: true,
      offset: options.redesign ? toolTipStyle.getOffset(placement) : toolTipStyle.getDefaultOffset(),
      maxWidth: 350,
      popperOptions: {
        onCreate: (data) => {
          Equation.typeset(data.instance.popper)
        },
        modifiers: modifiers
      }
    })
  }
}
