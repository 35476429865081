import {
  AUDIO_ENDED_EVENT,
  AUDIO_PAUSE_EVENT,
  AUDIO_PLAY_EVENT,
  AUDIO_RESUME_EVENT,
  EventBus,
  VIDEO_ENDED_EVENT,
  VIDEO_PAUSE_EVENT,
  VIDEO_PLAY_EVENT,
  VIDEO_RESUME_EVENT
} from '../common/EventBus'

const $script = require('scriptjs')

export default class Video {
  static get playerApiSrc () { return 'https://thiememeulenhoff.bbvms.com/launchpad/' };

  constructor (el, options) {
    this.initPlayerForElement(el)
  }

  initPlayerForElement (el) {
    if (typeof window.bluebillywig === 'undefined') {
      // The bbw player API is not yet present; fetch script
      $script(Video.playerApiSrc, () => {
        this.createPlayerForElement(el)
      })
    } else {
      this.createPlayerForElement(el)
    }
  }

  createPlayerForElement (el) {
    // Remove dummy element
    el.innerHTML = ''

    const isAudioPlayer = el.classList.contains('audio-player') || el.classList.contains('asset__audio-placeholder');
    const vmsId = el.getAttribute('data-vms-id')

    if (vmsId === undefined) {
      throw Error('Video container does not have a VMS ID')
    }

    const player = this.createPlayer(
      'https://thiememeulenhoff.bbvms.com/',
      vmsId,
      el,
      false
    )

    el.setAttribute('data-vms-player-id', player._id)

    let hasStarted = false
    player.on('play', () => {
      if (isAudioPlayer) {
        EventBus.$emit(hasStarted ? AUDIO_RESUME_EVENT : AUDIO_PLAY_EVENT, vmsId)
      } else {
        EventBus.$emit(hasStarted ? VIDEO_RESUME_EVENT : VIDEO_PLAY_EVENT, vmsId)
      }

      hasStarted = true
      this.pauseOtherPlayers(player)
    })
    player.on('pause', () => {
      // ignore this event, as it is the end of the clip
      if (player.getCurrentTime() >= player.getDuration()) {
        return
      }
      isAudioPlayer ? EventBus.$emit(AUDIO_PAUSE_EVENT, vmsId) : EventBus.$emit(VIDEO_PAUSE_EVENT, vmsId)
    })
    player.on('ended', () => {
      isAudioPlayer ? EventBus.$emit(AUDIO_ENDED_EVENT, vmsId) : EventBus.$emit(VIDEO_ENDED_EVENT, vmsId)
      hasStarted = false
    })
  }

  createPlayer (vmsBaseUrl, vmsId, el, autoplay) {
    return new window.bluebillywig.Player(this.getPlayerUrl(
      vmsBaseUrl,
      vmsId
    ), { target: el, autoPlay: autoplay, controlBar: 'show' })
  }

  getPlayout () {
    return 'basic_msp20_responsive'
  }

  getPlayerUrl (baseUrl, itemId) {
    return baseUrl + 'p/' + this.getPlayout() + '/c/' + itemId + '.json'
  }

  pauseAllPlayers () {
    if (typeof window.bluebillywig === 'undefined' || window.bluebillywig.players == null) {
      return
    }

    for (let i = 0; i < window.bluebillywig.players.length; i++) {
      window.bluebillywig.players[i].pause()
    }
  }

  // Pause all players except current:
  pauseOtherPlayers (player) {
    const playerId = player._id
    for (let i = 0; i < window.bluebillywig.players.length; i++) {
      if (window.bluebillywig.players[i]._id !== playerId) {
        window.bluebillywig.players[i].pause()
      }
    }
  }
}
