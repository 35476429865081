'use strict'

import { createPopper } from '@popperjs/core'

export class ContextMenuPopper {
  constructor (attachToBottom) {
    this.attachToBottom = attachToBottom
    this._popperInstance = null
  }

  createPopper (trigger, menu) {
    this._popperInstance = createPopper(
      trigger,
      menu,
      {
        placement: 'right-start',
        modifiers: [
          {
            name: 'computeStyles',
            options: { gpuAcceleration: false }
          },
          {
            name: 'offset',
            options: { offset: this.attachToBottom ? [24, -24] : [-8, -24] }
          },
          {
            name: 'preventOverflow',
            options: { mainAxis: false }
          }
        ]
      }
    )
  }

  destroy () {
    if (this._popperInstance === null) {
      return
    }
    this._popperInstance.destroy()
    this._popperInstance = null
  }
}
