'use strict'

import Translator from 'bazinga-translator'

export default class VueTranslator {
  static install (Vue, options) {
    Vue.prototype.$t = (key, options = {}) => {
      return Translator.trans(key, options)
    }
    Vue.prototype.$transChoice = (key, count, options = {}) => {
      return Translator.transChoice(key, count, options)
    }
  }
}
