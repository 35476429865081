'use strict'

import request from '../../util/request'
import Routing from '../../util/routing'

let allTerms = null
let termsPromise = null

export class TermCannotBeFoundError extends Error {}

export class TermService {
  static async getTerm (reference) {
    if (!msp.streamCode) {
      throw new TermCannotBeFoundError(`Term content not found "${reference}")`)
    }

    if (termsPromise === null) {
      const url = Routing.generate('content_terms', { streamCode: msp.streamCode }) + '?revision=' + msp.streamRevision
      termsPromise = request(url)
    }

    if (allTerms === null) {
      const response = await termsPromise
      allTerms = [];
      for (const term of response.data) {
        allTerms.push(term);
      }
    }

    const foundTerm = allTerms.find((term) => {
       if (term.id.includes(reference)) {
         return term;
       }
    })

    if (undefined === foundTerm) {
      throw new TermCannotBeFoundError(`Term content not found "${reference}")`)
    }

    return foundTerm;
  }
}
