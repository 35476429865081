var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "share-menu" }, [
    _c(
      "div",
      { class: _vm.shareMenuTitleClasses },
      [
        _vm.useRedesign
          ? _c("inline-icon", { attrs: { icon: "share" } })
          : _c("i", {
              staticClass: "icon icon-share share-menu__title-icon",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clickIcon.apply(null, arguments)
                },
              },
            }),
        _vm._v(" "),
        _c("span", {
          class: _vm.shareHeaderClass,
          domProps: { innerHTML: _vm._s(_vm.shareHeader) },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { class: _vm.shareMenuButtonClasses }, [
      _vm.msTeamsEnabled
        ? _c("div", { class: _vm.msTeamsShareButtonClasses }, [
            _c("div", {
              ref: "msteams",
              class: _vm.shareMenuButtonIconClasses,
              attrs: {
                "data-href": _vm.shareLink,
                "data-msg-text": _vm.shareDescription,
                "data-assign-title": _vm.shareTitle,
              },
              on: { click: _vm.close },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.googleClassroomEnabled
        ? _c("div", { class: _vm.googleClassRoomButtonClasses }, [
            _c("div", {
              ref: "googleclassroom",
              class: _vm.shareMenuButtonIconClasses,
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }