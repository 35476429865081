'use strict'

import bindElements from './bind-elements'
import ImageModal from '../components/ImageModal'
import TermPopover from '../components/TermPopover'
import Tooltip from '../components/Tooltip'
import Poller from '../components/Poller'
import Video from '../components/Video'
import Equation from '../components/Equation'
import Collapse from '../components/Collapse'
import ReadSpeaker from '../components/ReadSpeaker'
import ContentModalLink from '../components/ContentModalLink'
import WhenItSticks from './when-it-sticks.js'
import DocReaderButton from '../components/DocReaderButton'
import Share from '../components/share/Share'
import ItemContext from '../components/item-context-menu/ItemContext'
import LoadingState from '../components/LoadingState'
import { EventBus, SETUP_CONTENT_ELEMENTS } from '../common/EventBus'
import SwitchAttempt from '../components/SwitchAttempt'
import TextareaAutosize from '../components/TextareaAutosize'
import Dialogue from '../components/Dialogue'
import AssignmentCard from '../components/AssignmentCard'
import ExternalLink from '../components/ExternalLink'
import DownloadLink from '../components/DownloadLink'

export function setupContentElements (container) {
  const defaultOptions = {
    redesign: 'redesign' === window.activeLayout
  }
  bindElements(container, '.js-poller', Poller, defaultOptions)
  bindElements(container, '.js-zoom-image', ImageModal, defaultOptions)
  bindElements(container, '.js-term-tooltip', TermPopover, defaultOptions)
  bindElements(container, '.js-tooltip', Tooltip, defaultOptions)
  bindElements(container, '.js-vms-container', Video, defaultOptions)
  bindElements(container, '.js-collapse', Collapse)
  ReadSpeaker.fixDuplicatePreserve(container)
  bindElements(container, '.js-readspeaker', ReadSpeaker)
  bindElements(container, '.js-content-modal-link', ContentModalLink)
  bindElements(container, '.js-when-it-sticks', WhenItSticks)
  bindElements(container, '.js-docreader-button', DocReaderButton)
  bindElements(container, 'share-menu', Share)
  bindElements(container, '.js-item-context-menu', ItemContext)
  setTimeout(() => {
    Equation.typeset(container)
  }, 0)

  bindElements(container, '.js-add-loading-state', LoadingState)
  bindElements(container, '.js-switch-attempt', SwitchAttempt)

  bindElements(container, '.js-textarea-autosize', TextareaAutosize)
  bindElements(container, '.js-dialogue', Dialogue)
  bindElements(container, '.js-assignment-card', AssignmentCard)

  bindElements(container, '.js-external-link', ExternalLink)
  bindElements(container, '.js-download-link', DownloadLink)

  EventBus.$emit(SETUP_CONTENT_ELEMENTS, container)
}
