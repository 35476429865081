'use strict'

/**
 * @param {HTMLImageElement} image
 */
export default function imageLoaded (image) {
  return new Promise(resolve => {
    image.addEventListener('load', () => {
      resolve(image)
    })
  })
}
