'use strict'

import { EventBus, NAVIGATE_EVENT, VIEW_EVENT } from '../common/EventBus'
import { VIEW_EVENT_INTERVAL_MS } from './ThasService'

export default class NavigateAndViewEventProducer {
  constructor (url) {
    this.iteration = 0

    EventBus.$emit(NAVIGATE_EVENT, url, null)
    this.interval = setInterval(() => {
      EventBus.$emit(VIEW_EVENT, url, this.iteration)
      this.iteration++
    }, VIEW_EVENT_INTERVAL_MS)
  }

  stop () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
