<template>
  <a
    @click.prevent.stop="onClick"
    @keydown.enter.prevent.stop="onClick"
    tabindex="0"
    class="inline-flex flex-row items-center justify-center bg-white shadow rounded-sm"
    :class="buttonClass"
    :aria-label="label"
  >
    <inline-icon :class="iconClass" :key="icon" :icon="icon" :size="'small' === size ? 3 : 4"></inline-icon>
  </a>
</template>

<script>

import InlineIcon from '../InlineIcon'

const SIZE_CLASSES = {
  'small': 'w-6 h-6',
  'default': 'w-8 h-8'
}

export default {
  components: {
    InlineIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    label: String,
    icon: String,
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonClass () {
      if (this.disabled) {
        return SIZE_CLASSES[this.size] + ' cursor-default bg-gray-400 text-gray-500'
      }
      return SIZE_CLASSES[this.size] + ' cursor-pointer bg-white active:text-blue-dark text-black hover:text-blue focus:text-blue outline-none'
    },
  },
  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>
