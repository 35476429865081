'use strict'

export default class TooltipStyle {
  constructor (backgroundClass, arrowClass, textClass, isRedesign) {
    this._backgroundClass = backgroundClass
    this._arrowClass = arrowClass
    this._textClass = textClass
    this._isRedesign = isRedesign
  }

  static createDefault (isRedesign) {
    return new TooltipStyle(
      'bg-blue',
      'text-blue',
      'text-white',
      isRedesign
    )
  }

  static createGray (isRedesign) {
    return new TooltipStyle(
      'bg-gray-600',
      'text-gray-600',
      'text-white text-3xs',
      isRedesign
    )
  }

  arrowSvgs () {
    return this.arrowSvg('all')
  }

  arrowSvg (type, startHidden = true) {
    const svgArrowData = {
      left: { width: 12, height: 24, polygons: '0,0 12,12 0,24', classes: 'left-full top-1/2 -translate-y-1/2' },
      right: {
        width: 12,
        height: 24,
        polygons: '12,0 0,12 12,24',
        classes: 'left-0 top-1/2 transform -translate-y-1/2 -translate-x-full'
      },
      top: { width: 24, height: 12, polygons: '0,0 12,12 24,0', classes: 'left-1/2 top-full -translate-x-1/2' },
      'top-start': { width: 24, height: 12, polygons: '0,0 12,12 24,0', classes: 'left-8 top-full -translate-x-1/2' },
      'top-end': { width: 24, height: 12, polygons: '0,0 12,12 24,0', classes: 'right-2 top-full -translate-x-1/2' },
      bottom: {
        width: 24,
        height: 12,
        polygons: '0,12 12,0 24,12',
        classes: 'left-1/2 top-0 -translate-x-1/2 -translate-y-full'
      }
    }

    if ('all' === type) {
      const keys = Object.keys(svgArrowData)
      let arrows = ''
      keys.forEach(key => {
        arrows += this.arrowSvg(key, startHidden)
      })
      return arrows
    }

    const data = svgArrowData[type]
    const hidden = startHidden === false ? '' : 'hidden'
    return `<svg width="${data.width}" height="${data.height}" xmlns="http://www.w3.org/2000/svg"
        class="js-svg-arrow-${type} ${hidden} absolute ${this._arrowClass} fill-current transform ${data.classes}">
        <polygon points="${data.polygons}"/>
      </svg>`
  }

  getOffset (placement) {
    const tooltipOffset = {
      top: [0, 15],
      'top-start': [0, 15],
      'top-end': [32, 15],
      bottom: [0, 15],
      left: [0, 12],
      right: [0, 12]
    }

    return tooltipOffset[placement]
  }

  getDefaultOffset () {
    return [0, 15]
  }

  wrapContent (content) {
    if (this._isRedesign) {
      return this.arrowSvgs() +
        `<div class="rounded-lg ${this._backgroundClass} ${this._textClass} py-3.5 px-5">${content}</div>`
    }
    return `<div class="tooltip__content">${content}</div>`
  }

  getModifiers () {
    if (!this._isRedesign) {
      return []
    }

    const modifyArrow = (instance, placement, callback) => {
      const arrow = instance.state.elements.popper.querySelector(`.js-svg-arrow-${placement}`)
      if (arrow instanceof Element) {
        callback(arrow)
      }
    }

    let previousPlacement = null
    return [{
      name: 'svgArrowModifier',
      enabled: true,
      phase: 'main',
      fn (instance) {
        if (instance.state.placement !== previousPlacement) {
          if (previousPlacement !== null) {
            modifyArrow(instance, previousPlacement, (arrow) => {
              arrow.classList.add('hidden')
            })
          }
          modifyArrow(instance, instance.state.placement, (arrow) => {
            arrow.classList.remove('hidden')
          })
          previousPlacement = instance.state.placement
        }
      }
    }]
  }
}
