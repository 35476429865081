<template>
  <modal v-if="opened" :loading="loading" :close-button-inside="true" :close-button-position="closeButtonPosition" @close="close" class="modal--content">
    <div class="modal__inner-content" ref="content" v-html="content"></div>
  </modal>
</template>

<script>
  import request from '../../util/request'
  import Modal from './Modal'

  export default {
    components: {
      Modal
    },
    data: function () {
      return {
        opened: false,
        content: null,
        loading: false,
        closeButtonPosition: 0
      }
    },
    props: {
      url: String
    },
    mounted() {
      this.open();
    },
    methods: {
      async load() {
        if (this.content !== null) {
          return
        }
        if (this.loading) {
          return
        }
        this.loading = true

        this.content = ''
        this.error = ''

        try {
          const response = await request(this.url)
          this.content = response.data

          await this.$nextTick();

          this.closeButtonPosition = this.$refs.content.clientWidth
        } catch (e) {
          if (e.response) {
            this.error = e.response.data
            return;
          }
          this.error = e.message;
        } finally {
          this.loading = false
        }
      },
      close () {
        this.opened = false
      },
      open () {
        this.opened = true
        this.load();
      }
    }
  }
</script>
