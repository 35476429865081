var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.contextMenuClasses }, [
    _c(
      "a",
      {
        ref: "trigger",
        class: _vm.contextMenuButtonClasses,
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.open.apply(null, arguments)
          },
        },
      },
      [
        _vm.useRedesign
          ? _c("inline-icon", {
              attrs: { icon: _vm.icon, size: _vm.isItemAssignmentCard ? 3 : 4 },
            })
          : _c("i", {
              class: `icon icon-${_vm.icon} context-menu__button-icon`,
            }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isOpen
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.close,
                expression: "close",
              },
            ],
            ref: "menu",
            class: _vm.contextMenuContentClasses,
          },
          [_vm._t("default", null, { close: _vm.close })],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }