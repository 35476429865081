'use strict'

import Vue from 'vue'
import MediaModal from './modal/MediaModal'
import { EventBus, IMAGE_MODAL_OPEN_EVENT } from '../common/EventBus'

export default class ImageModal {
  constructor (element, options) {
    element.addEventListener('click', (e) => {
      e.preventDefault()

      const ComponentClass = Vue.extend(MediaModal)

      const titleElement = element.querySelector('.caption__title')
      const instance = new ComponentClass({
        propsData: {
          imageUrl: element.href,
          imageTitle: titleElement ? titleElement.innerHTML : '',
          redesign: true === options.redesign
        }
      })
      instance.$mount()

      const thamIdElement = element.querySelector(['[data-tham-id]']) ?? element.parentNode.querySelector('img[data-tham-id]')
      if (thamIdElement !== null) {
        EventBus.$emit(IMAGE_MODAL_OPEN_EVENT, thamIdElement.dataset.thamId)
      }

      instance.$on('close', () => {
        instance.$destroy()
      })
      document.body.appendChild(instance.$el)
    })
  }
}
