'use strict'

import request from '../util/request'
import Translator from 'bazinga-translator'

export default class Poller {
  constructor (element, options) {
    this.element = element
    this.dots = 0
    this.pollUrl = element.dataset.pollUrl
    this.redirectUrl = element.dataset.redirectUrl

    this.poll()
    this.intervalId = setInterval(() => {
      this.poll()
    }, 3000);
  }

  async poll() {
    this.handleResponse(await request(this.pollUrl))
  }

  handleResponse (response) {
    const state = response.data.state
    const position = response.data.position

    if ('finished' === state) {
      this.element.innerHTML = this.stateMessage(state, position)
      clearInterval(this.intervalId)
      window.location.href = this.redirectUrl

      return
    }

    this.element.innerHTML = this.stateMessage(state, position) + this.dotStr()
  }

  stateMessage (state, position) {
    return Translator.trans('component.printsheets.' + state, {position: position})
  }

  dotStr() {
    this.dots = this.dots > 2 ? 1 : this.dots + 1

    return String('.').repeat(this.dots) + '<span style="opacity: 0">' + String('.').repeat(3 - this.dots) + '</span>'
  }
}
