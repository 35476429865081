import { EventBus, TOOL_USE_EVENT } from '../common/EventBus'

const toolTypeRegexes = [
  { type: 'Mixer', regex: /(dev.edition|edition.thiememeulenhoff.nl)(.*)(\/mixer\/package|\/mixer\/playlist)/i },
  { type: 'Digibord', regex: /(digibord.thiememeulenhoff.nl)/i },
  { type: 'Alcarta', regex: /(hub.thiememeulenhoff.nl\/schooltas\?EAN=)(9789006814644|9789006182194)/i },
  { type: 'Alcarta', regex: /(alcarta.thiememeulenhoff.nl)/i },
]

const getToolType = href => {
  for (const toolTypeRegex of toolTypeRegexes) {
    if (toolTypeRegex.regex.test(href)) {
      return toolTypeRegex.type
    }
  }

  return null
}

export default class ExternalLink {
  constructor (element) {
    const toolType = getToolType(element.href)

    element.addEventListener('click', () => {
      EventBus.$emit(TOOL_USE_EVENT, element.href, toolType)
    })
  }
}
