<template>
  <div :class="contextMenuClasses">
    <a ref="trigger" @click.prevent="open" :class="contextMenuButtonClasses">
      <inline-icon :icon="icon" v-if="useRedesign" :size="isItemAssignmentCard ? 3 : 4"></inline-icon>
      <i v-else :class="`icon icon-${icon} context-menu__button-icon`"></i>
    </a>
    <div v-if="isOpen" ref="menu" :class="contextMenuContentClasses" v-on-clickaway="close">
      <slot :close="close"></slot>
    </div>
  </div>
</template>

<script>
import { ContextMenuPopper } from './ContextMenuPopper'
import {mixin as clickaway} from 'vue-clickaway'
import InlineIcon from '../components/InlineIcon'

export default {
  mixins: [ clickaway ],
  components:{
    InlineIcon
  },
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    icon: String,
    section: String,
    attachToBottom: Boolean,
    useRedesign: {
      type: Boolean,
      default: false
    },
    isAssignmentCard: {
      type: Boolean,
      default: false
    }
  },
  beforeDestroy () {
    this.destroyPopper()
  },
  computed: {
    isItemAssignmentCard() {
      return this.section === 'item' && this.isAssignmentCard
    },
    contextMenuClasses() {
      // this.section --> theory, referentce, my-content, item
      if(this.useRedesign) {
        return [
          'absolute text-left z-50',
          {'top-0 right-0': this.isItemAssignmentCard},
          {'top-11 right-5': this.section === 'item' && !this.isAssignmentCard},
          {'top-16 right-3': this.section === 'theory'}
        ]
      }
      return 'context-menu context-menu--' + this.section
    },
    contextMenuButtonClasses() {
      if (this.useRedesign && this.isItemAssignmentCard) {
        return ['flex', 'items-center', 'justify-center', 'cursor-pointer', 'text-red', 'bg-white', 'rounded', 'w-5', 'h-5', 'shadow-sm']
      }
      if(this.useRedesign) {
        return 'cursor-pointer'
      }
      return 'context-menu__button'
    },
    contextMenuContentClasses() {
      if(this.useRedesign) {
        return 'absolute flex flex-col z-50 top-6 p-2 bg-white shadow-md rounded'
      }
      return 'context-menu__content'
    },
  },
  methods: {
    hasPopper () {
      return this.$options.popper instanceof ContextMenuPopper
    },
    createPopper () {
      if (!this.hasPopper()) {
        this.$options.popper = new ContextMenuPopper(this.attachToBottom)
      }
    },
    destroyPopper () {
      if (this.hasPopper()) {
        this.$options.popper.destroy()
        this.$options.popper = null
      }
    },
    async open() {
      this.createPopper()
      this.isOpen = true
      await this.$nextTick()

      this.$options.popper.createPopper(
        this.$refs.trigger,
        this.$refs.menu
      )
    },
    close () {
      this.isOpen = false
      this.destroyPopper()
    }
  }
}
</script>
