<template>
  <context-menu
    v-if="isActive"
    :icon="getIconClass"
    :section="section"
    :attach-to-bottom="!hasOnlyShare"
    :is-assignment-card="isAssignmentCard"
    :use-redesign="useRedesign"
    >
    <template v-slot="slotScope">
      <div v-if="hasResultLink" :class="getContentMenuSectionClasses">
        <a class="context-menu__button" :href="resultUrl">
        <inline-icon icon="results" v-if="useRedesign"></inline-icon>
        <i v-else class="icon-tm-analytics"></i> {{ $t('generic.term.results') }}
        </a>
      </div>

      <div v-if="hasProgress" :class="getContentMenuSectionClasses">
        <div class="context-menu__button" @click="showProgressModal">
        <inline-icon icon="results" v-if="useRedesign"></inline-icon>
          <i class="icon icon-horizontal-bar-chart"></i> {{ $t('component.progress.button.title') }}
        </div>
      </div>

      <share-with-buttons
        :class="shareWithButtonClasses"
        v-if="hasShare"
        :share-header="shareHeader"
        :share-buttons="shareButtons"
        :share-link="shareLink"
        :share-description="shareDescription"
        :share-title="shareTitle"
        :use-redesign="useRedesign"
        @close="slotScope.close"
        @clickIcon="slotScope.close"
      />
    </template>
  </context-menu>
</template>

<script>
  import ShareWithButtons from "../share/ShareWithButtons"
  import ContextMenu from '../ContextMenu'
  import InlineIcon from '../../components/InlineIcon'
  import {EventBus, PROGRESS_MODAL_OPEN_EVENT} from "../../common/EventBus"

  export default {
    components: {
      ShareWithButtons,
      ContextMenu,
      InlineIcon
    },

    data() {
      // No progress modal yet in the redesign, so for now we disable the progress link
      const hasResultLink = typeof this.resultUrl === 'string' && this.resultUrl !== ''
      const hasProgress = !this.useRedesign && typeof this.progressUrl === 'string' && this.progressUrl !== ''
      const hasShare = typeof this.shareLink === 'string' && this.shareLink !== ''

      const hasOnlyShare = hasShare && !hasProgress && !hasResultLink

      return {
        isActive: hasResultLink || hasProgress || hasShare,
        hasResultLink: hasResultLink,
        hasProgress: hasProgress,
        hasShare: hasShare,
        hasOnlyShare: hasOnlyShare
      }
    },

    props: {
      section: String,
      useRedesign: {
        type: Boolean,
        default: false
      },
      isAssignmentCard: {
        type: Boolean,
        default: false
      },
      // link to result screen
      resultUrl: String,

      // Values for progress modal
      progressUrl: String,

      // Values for Sharing to msteams etc
      shareLink: String,
      shareButtons: Array,
      shareHeader: String,
      shareDescription: String,
      shareTitle: String,
    },

    methods: {
      showProgressModal() {
        EventBus.$emit(PROGRESS_MODAL_OPEN_EVENT, this.progressUrl)
      },
    },

    computed: {
      getIconClass() {
        if(this.useRedesign) {
          /* Todo: geen idee waar 'rows' in het redesign door vervangen moet worden... */
          return this.hasOnlyShare ? 'menu' : 'menu'
        }
        return this.hasOnlyShare ? 'share' : 'rows';
      },
      getContentMenuSectionClasses() {
        if(this.useRedesign) {
          return 'p-2 whitespace-nowrap'
        }
        return 'context-menu__section'
      },

      shareWithButtonClasses() {
        if(this.useRedesign) {
          return 'p-2 whitespace-nowrap'
        }
        return 'context-menu__section'
      },

      getContentMenuButtonClasses() {
        if(this.useRedesign) {

        }
        return 'context-menu__button';
      }
    }
  }
</script>
