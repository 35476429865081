var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "inline-flex flex-row items-center justify-center bg-white shadow rounded-sm",
      class: _vm.buttonClass,
      attrs: { tabindex: "0", "aria-label": _vm.label },
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("inline-icon", {
        key: _vm.icon,
        class: _vm.iconClass,
        attrs: { icon: _vm.icon, size: "small" === _vm.size ? 3 : 4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }