'use strict'

import tippy from 'tippy.js'
import { TermCannotBeFoundError, TermService } from './term/TermService'
import Equation from './Equation'
import TooltipStyle from './tooltip/TooltipStyle'

export default class TermPopover {
  constructor (termLink, options) {
    if (!termLink.dataset.reference) {
      throw Error('Term link does not have required data field "reference"')
    }

    this.termLink = termLink
    this.options = options

    this.setup()
  }

  async setup () {
    const termReference = this.termLink.dataset.reference
    try {
      this.termLink.addEventListener('click', function (evt) {
        evt.preventDefault()
      })

      const term = await TermService.getTerm(termReference)
      const toolTipStyle = TooltipStyle.createDefault(this.options.redesign)
      const modifiers = toolTipStyle.getModifiers()
      const parentParagraph = document.querySelector(`[data-reference='${term.id}']`) === null
        ? document.querySelector(`[data-reference='${termReference}']`).closest('p')
        : document.querySelector(`[data-reference='${term.id}']`).closest('p')

      const content = this.options.redesign
        ? `<h3 class="mb-4">${term.term}</h3><i class="not-italic">${term.definition}</i>`
        : `<h3 class="tooltip__title">${term.term}</h3><i class="not-italic">${term.definition}</i>`

      tippy(this.termLink, {
        content: toolTipStyle.wrapContent(content),
        allowHTML: true,
        trigger: 'click',
        placement: 'top',
        interactive: true,
        offset: this.options.redesign ? toolTipStyle.getOffset('top') : toolTipStyle.getDefaultOffset(),
        maxWidth: 650,
        popperOptions: {
          onCreate: (data) => {
            Equation.typeset(data.instance.popper)
          },
          modifiers: modifiers
        }, appendTo: () => { // append to parent of term
          return parentParagraph
        }
      })
    } catch (e) {
      if (e instanceof TermCannotBeFoundError) {
        console.warn(e.message)
        if (this.options.redesign) {
          this.termLink.classList.add('line-through', 'text-red')
        } else {
          this.termLink.classList.add('link--has-broken-reference')
        }

        this.termLink.addEventListener('click', function (evt) {
          evt.preventDefault()
          alert(e.message)
        })
        return
      }
      throw e
    }
  }
}
