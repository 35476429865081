export default class SwitchAttempt {
  constructor (element) {
    element.addEventListener('click', function (evt) {
      evt.preventDefault()

      if (element.classList.contains('is-current')) {
        return
      }

      // not cachable as the content is dynamically loaded :/
      document.querySelectorAll('.attempt.is-showing').forEach((el) => {
        el.classList.remove('is-showing')
      })
      document.querySelectorAll('.attempt-link.is-current').forEach((el) => {
        el.classList.remove('is-current')
      })

      element.classList.add('is-current')
      const target = document.querySelector(element.getAttribute('href'))
      target.classList.add('is-showing')
    })
  }
}
