<template>
  <focus-trap>
    <div :class="classes" role="dialog" aria-modal="true">
      <template v-if="redesign">
        <div ref="modalContent" class="overflow-auto h-full js-focus" tabindex="0" :class="{'flex items-center justify-center': isZoomable && !zoomed}" @keydown.enter.prevent="toggleZoom">
          <div v-if="mediaType === 'video'" :id="vmsContainerId" ref="video" v-on-clickaway="close" :data-vms-id="vmsId"></div>
          <template v-if="mediaType === 'image'">
            <img @click.prevent="toggleZoom()"
                 ref="image"
                 :src="imageUrl"
                 :alt="imageTitle"
                 v-on-clickaway="close"
                 :class="{
                   'cursor-zoom-in': isZoomable && !zoomed,
                   'cursor-zoom-out': isZoomable && zoomed,
                   'max-h-full': !zoomed,
                   'max-w-none': zoomed
               }">
          </template>
        </div>
        <div class="absolute top-5 right-5 flex flex-col space-y-5 items-center justify-center">
          <button-icon-only icon="close" @click="close" label="Sluiten"></button-icon-only>
          <button-icon-only :icon="zoomed ? 'magnifier-minus' : 'magnifier-plus'" @click="toggleZoom" :label="zoomed ? 'Uitzoomen': 'Inzoomen'"></button-icon-only>
        </div>
      </template>
      <template v-else>
        <div class="media-modal__content" ref="modalContent">
          <div v-if="mediaType === 'video'" :id="vmsContainerId" ref="video" tabindex="0" class="media-modal__video js-focus" v-on-clickaway="close" :data-vms-id="vmsId"></div>
          <div v-if="mediaType === 'image'" class="media-modal__image-wrapper js-focus" tabindex="0">
            <img @click.prevent="toggleZoom()"
                 ref="image"
                 :src="imageUrl"
                 :alt="imageTitle"
                 v-on-clickaway="close"
                 class="media-modal__image" :class="{
                 'media-modal__image--portrait': portraitOrientation,
                 'media-modal__image--zoomable': isZoomable,
                 'media-modal__image--zoomed': zoomed
               }">
          </div>
        </div>
        <button class="close-button close-button--modal close-button--dark" @click="close()"></button>
      </template>
    </div>
  </focus-trap>
</template>

<script>
  import Video from '../Video'
  import { mixin as clickaway } from 'vue-clickaway'
  import imageLoaded from '../../util/image-loaded'

  import disableBodyScroll from '../../util/disable-body-scroll'
  import { enableBodyScroll } from 'body-scroll-lock';

  import ButtonIconOnly from '../buttons/ButtonIconOnly'
  import { FocusTrap } from 'focus-trap-vue'

  export default {
    components: {
      ButtonIconOnly,
      FocusTrap
    },
    mixins: [clickaway],
    props: {
      vmsId: String,
      imageUrl: String,
      imageTitle: String,
      redesign: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        tabindex: '-1',
        zoomed: false,
        imageDimensions: null,
        screenDimensions: null,
        portraitOrientation: false
      }
    },
    computed: {
      classes() {
        if (this.redesign) {
          return {'fixed inset-0 items-center flex items-center justify-center z-50 bg-black bg-opacity-50': true}
        }
        return {'media-modal': true}
      },
      vmsContainerId () {
        return 'vms-container-' + this.vmsId
      },
      mediaType () {
        if (this.vmsId) {
          return 'video'
        }
        return 'image'
      },
      isZoomable () {
        if (this.imageDimensions === null || this.screenDimensions === null) {
          return false
        }
        if (this.portraitOrientation) {
          return this.imageDimensions.height > this.screenDimensions.height
        }
        return this.imageDimensions.width > this.screenDimensions.width || this.imageDimensions.height > this.screenDimensions.height
      }
    },
    mounted () {
      document.body.appendChild(this.$el)
      document.documentElement.classList.add('is-clipped')
      document.addEventListener('keydown', this.detectEscapeKey, true)
      if (this.mediaType === 'image') {
        this.setScreenDimensions()
        window.addEventListener('resize', this.setScreenDimensions)
        imageLoaded(this.$refs.image).then((image) => {
          this.imageDimensions = {
            width: image.naturalWidth,
            height: image.naturalHeight
          }
          this.portraitOrientation = image.naturalHeight > image.naturalWidth
        })
      }
      if (this.mediaType === 'video') {
        new Video(this.$refs.video, {redesign: this.redesign})
      }

      this.$nextTick().then(() => {
        this.$el.querySelector('.js-focus').focus()
        disableBodyScroll(this.$refs.modalContent)
      })
    },
    beforeDestroy: function () {
      enableBodyScroll(this.$refs.modalContent)

      if (this.$el.parentNode === document.body) {
        document.body.removeChild(this.$el)
      }
      document.documentElement.classList.remove('is-clipped')
      document.removeEventListener('keydown', this.detectEscapeKey, true)
      window.removeEventListener('resize', this.setScreenDimensions)
    },
    methods: {
      detectEscapeKey (e) {
        if (e.keyCode === 27) {
          this.close()
        }
      },
      setScreenDimensions () {
        this.screenDimensions = {
          width: window.innerWidth,
          height: window.innerHeight
        }
      },
      toggleZoom () {
        if (!this.zoomed && !this.isZoomable) {
          return
        }
        this.zoomed = !this.zoomed
      },
      close () {
        this.$emit('close')
      }
    }
  }
</script>
