'use strict'

const className = 'is-stuck'

/**
 * Observes the 'trigger' and when it is scrolled outside the 'root' (defaults to body)
 * it adds a class to the given element (selector).
 * Params:
 *  - element: sticky element
 *  - trigger: element being observed
 *  - rootMargin: can either be positive or negative, is applied as an offset to cater for additional space (like a (pre)header)
 */
export default class WhenItSticks {
  constructor (element) {
    if (!window.IntersectionObserver) {
      return
    }

    this.trigger = document.querySelector('.' + element.dataset.stickyTrigger) || element.previousElementSibling
    if (!this.trigger) {
      return
    }
    this.rootMargin = element.dataset.stickyMargin || '0px'

    let observer = new window.IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio === 0) {
        element.classList.add(className)
      } else {
        element.classList.remove(className)
      }
    }, { threshold: [0.0, 1.0], rootMargin: this.rootMargin })

    observer.observe(this.trigger)
  }
}
