<template>
  <div class="modal modal--card is-active" ref="modal" data-test="modal">
    <div class="modal-background"></div>

    <article class="modal__inner" v-on-clickaway="close" v-on-escape="close">
      <header class="modal__header"
              :class="[{ 'modal__header--no-bg' : !image }]"
              :style="image ? 'background-image: url(' + image + ');' : ''">
        <div class="modal__header-inner">
          <p class="modal__pretitle" v-if="meta !== undefined">
            <template v-if="meta.trefwoorden">{{ meta.trefwoorden[0] }}</template>
            <template v-if="meta.leerjaar"> • {{ metaLeerjaarLabel }} {{ leerjaar }}</template>
          </p>
          <h4 class="modal__title" data-test="modal-title">{{ title }}</h4>
        </div>

        <a class="modal__close" @click="close()" data-test="modal-close-button"></a>
      </header>
      <div :data-test="'lesson-topic-modal-content-' + index" class="modal__content" ref="content">
        <slot></slot>
      </div>
    </article>
  </div>
</template>

<script>
import mixins from '../../util/mixins'
import { mixin as clickaway } from 'vue-clickaway'
import { mixin as onescape } from '../../util/on-escape'
import { setupContentElements } from '../../util/setup-content-elements'
import { EventBus, MODAL_CLOSE_EVENT } from '../../common/EventBus'
import disableBodyScroll from '../../util/disable-body-scroll'
import { enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.esm'
import NavigateAndViewEventProducer from '../../thas/NavigateAndViewEventProducer'

export default {
  mixins: [mixins, clickaway, onescape],

  props: {
    index: String,
    title: String,
    image: String,
    meta: Object,
    metaLeerjaarLabel: String,
    viewEventUrl: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      leerjaar: this.getLeerjaar()
    }
  },

  mounted() {
    disableBodyScroll(this.$el)
    setupContentElements(this.$refs.content)
  },

  methods: {
    getLeerjaar () {
      if (this.meta === undefined) {
        return ''
      }
      return (Array.isArray(this.meta.leerjaar) && this.meta.leerjaar.length > 1) ? `${this.meta.leerjaar[0]} & ${this.meta.leerjaar[1]}` : this.meta.leerjaar[0]
    },
    stopNavigateAndViewEventProducer () {
      if (this.$options.navigateAndViewEventProducer instanceof NavigateAndViewEventProducer) {
        this.$options.navigateAndViewEventProducer.stop()
        this.$options.navigateAndViewEventProducer = null
      }
    },

    startNavigateAndViewEventProducer () {
      this.stopNavigateAndViewEventProducer()
      if (this.viewEventUrl !== '') {
        this.$options.navigateAndViewEventProducer = new NavigateAndViewEventProducer(this.viewEventUrl)
      }
    },

    close () {
      this.stopNavigateAndViewEventProducer()
      enableBodyScroll(this.$el)
      EventBus.$emit(MODAL_CLOSE_EVENT)

      this.$emit('close')
    }
  }
}
</script>
