'use strict';

import Vue from 'vue'
import ContentModal from './modal/ContentModal'

export default class ContentModalLink {
  constructor (element) {
    element.addEventListener('click', (e) => {
      e.preventDefault()

      const ComponentClass = Vue.extend(ContentModal)
      const instance = new ComponentClass({
        propsData: { url: element.href }
      })
      instance.$mount()
      document.body.appendChild(instance.$el)
    })
  }
}
