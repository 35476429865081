export default class DocReaderButton {
  constructor(element) {
    const streamLanguage = msp.streamLanguage || 'nl_NL'
    const url = encodeURIComponent(element.getAttribute('data-document-url'))
    const language = element.hasAttribute('lang') ? element.getAttribute('lang') : streamLanguage

    element.addEventListener('click', (event) => {
      event.stopPropagation()
      event.preventDefault()
      window.open(
        `https://docreader.readspeaker.com/docreader/?jsmode=1&cid=brjei&lang=${language}&url=${url}`
      )
    })
  }
}
