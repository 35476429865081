var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", [
    _c(
      "div",
      { class: _vm.classes, attrs: { role: "dialog", "aria-modal": "true" } },
      [
        _vm.redesign
          ? [
              _c(
                "div",
                {
                  ref: "modalContent",
                  staticClass: "overflow-auto h-full js-focus",
                  class: {
                    "flex items-center justify-center":
                      _vm.isZoomable && !_vm.zoomed,
                  },
                  attrs: { tabindex: "0" },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.toggleZoom.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.mediaType === "video"
                    ? _c("div", {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.close,
                            expression: "close",
                          },
                        ],
                        ref: "video",
                        attrs: {
                          id: _vm.vmsContainerId,
                          "data-vms-id": _vm.vmsId,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mediaType === "image"
                    ? [
                        _c("img", {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.close,
                              expression: "close",
                            },
                          ],
                          ref: "image",
                          class: {
                            "cursor-zoom-in": _vm.isZoomable && !_vm.zoomed,
                            "cursor-zoom-out": _vm.isZoomable && _vm.zoomed,
                            "max-h-full": !_vm.zoomed,
                            "max-w-none": _vm.zoomed,
                          },
                          attrs: { src: _vm.imageUrl, alt: _vm.imageTitle },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggleZoom()
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute top-5 right-5 flex flex-col space-y-5 items-center justify-center",
                },
                [
                  _c("button-icon-only", {
                    attrs: { icon: "close", label: "Sluiten" },
                    on: { click: _vm.close },
                  }),
                  _vm._v(" "),
                  _c("button-icon-only", {
                    attrs: {
                      icon: _vm.zoomed ? "magnifier-minus" : "magnifier-plus",
                      label: _vm.zoomed ? "Uitzoomen" : "Inzoomen",
                    },
                    on: { click: _vm.toggleZoom },
                  }),
                ],
                1
              ),
            ]
          : [
              _c(
                "div",
                { ref: "modalContent", staticClass: "media-modal__content" },
                [
                  _vm.mediaType === "video"
                    ? _c("div", {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.close,
                            expression: "close",
                          },
                        ],
                        ref: "video",
                        staticClass: "media-modal__video js-focus",
                        attrs: {
                          id: _vm.vmsContainerId,
                          tabindex: "0",
                          "data-vms-id": _vm.vmsId,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mediaType === "image"
                    ? _c(
                        "div",
                        {
                          staticClass: "media-modal__image-wrapper js-focus",
                          attrs: { tabindex: "0" },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.close,
                                expression: "close",
                              },
                            ],
                            ref: "image",
                            staticClass: "media-modal__image",
                            class: {
                              "media-modal__image--portrait":
                                _vm.portraitOrientation,
                              "media-modal__image--zoomable": _vm.isZoomable,
                              "media-modal__image--zoomed": _vm.zoomed,
                            },
                            attrs: { src: _vm.imageUrl, alt: _vm.imageTitle },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleZoom()
                              },
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("button", {
                staticClass:
                  "close-button close-button--modal close-button--dark",
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }