'use strict'

import Vue from 'vue'
import ShareMenu from './ShareMenu'

export default class Share {
  constructor (element) {
    this.vue = new Vue(
      {
        el: element.parentNode,
        components: { ShareMenu }
      }
    )
  }
}
