import {
  EventBus,
  ASSIGNMENTCARD_OPEN
} from '../common/EventBus'

export default class AssignmentCard {
  constructor (element) {
    const exerciseButtons = element.querySelector('.js-assignment-card-buttons')
    const exerciseList = element.querySelector('.js-assignment-card-list')

    const listItems = exerciseList.querySelectorAll('.js-question-list-item')

    // Calculate max-height in rem (h-4=1rem) from listItems, we use 4 rem per listitem, they can be multiple lines with long titles
    const maxHeight = Math.ceil(listItems.length * 4) + 'rem'

    exerciseButtons.style.maxHeight = maxHeight
    exerciseList.style.maxHeight = 0

    exerciseButtons.querySelector('.js-assignment-card-buttons-collapse').addEventListener('click', () => {
      EventBus.$emit(ASSIGNMENTCARD_OPEN, element.id)
    })

    const open = () => {
      exerciseButtons.style.maxHeight = 0
      setTimeout(() => {
        exerciseList.style.maxHeight = maxHeight
      }, 500)
    }

    const close = () => {
      exerciseList.style.maxHeight = 0;
      setTimeout(() => {
        exerciseButtons.style.maxHeight = maxHeight
      }, 500)
    }

    EventBus.$on(ASSIGNMENTCARD_OPEN, (id) => {
      if (id === element.id) {
        open()
      } else if (exerciseList.style.maxHeight !== 0) {
        close()
      }
    })

    exerciseList.querySelector('.js-assignment-card-list-collapse').addEventListener('click', () => {
      close()
    })
  }
}
