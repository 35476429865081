var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal modal--card is-active",
      attrs: { "data-test": "modal" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c(
        "article",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.close,
              expression: "close",
            },
            {
              name: "on-escape",
              rawName: "v-on-escape",
              value: _vm.close,
              expression: "close",
            },
          ],
          staticClass: "modal__inner",
        },
        [
          _c(
            "header",
            {
              staticClass: "modal__header",
              class: [{ "modal__header--no-bg": !_vm.image }],
              style: _vm.image
                ? "background-image: url(" + _vm.image + ");"
                : "",
            },
            [
              _c("div", { staticClass: "modal__header-inner" }, [
                _vm.meta !== undefined
                  ? _c(
                      "p",
                      { staticClass: "modal__pretitle" },
                      [
                        _vm.meta.trefwoorden
                          ? [_vm._v(_vm._s(_vm.meta.trefwoorden[0]))]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.meta.leerjaar
                          ? [
                              _vm._v(
                                " • " +
                                  _vm._s(_vm.metaLeerjaarLabel) +
                                  " " +
                                  _vm._s(_vm.leerjaar)
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    staticClass: "modal__title",
                    attrs: { "data-test": "modal-title" },
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
              ]),
              _vm._v(" "),
              _c("a", {
                staticClass: "modal__close",
                attrs: { "data-test": "modal-close-button" },
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "content",
              staticClass: "modal__content",
              attrs: { "data-test": "lesson-topic-modal-content-" + _vm.index },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }