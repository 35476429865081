'use strict'

import Vue from 'vue'
import ItemContextMenu from './ItemContextMenu'
import VueTranslator from '../../common/VueTranslator'

Vue.use(VueTranslator)

export default class ItemContext {
  constructor (element) {
    this.vue = new Vue(
      {
        el: element.parentNode,
        components: { ItemContextMenu }
      }
    )
  }
}
