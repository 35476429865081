<template>
  <span class="fill-current inline-block align-middle" aria-hidden="true" :class="classes" v-html="iconContent"></span>
</template>

<script>

const sizes = {
  1: 'w-1 h-1',
  2: 'w-2 h-2',
  3: 'w-3 h-3',
  4: 'w-4 h-4',
  5: 'w-5 h-5',
  6: 'w-6 h-6',
  7: 'w-7 h-7',
  8: 'w-8 h-8',
  9: 'w-9 h-9',
  10: 'w-10 h-10',
};

export default {
  data () {
    return {
      iconContent: require('@Icons/' + this.icon + '.svg')
    }
  },
  computed: {
    classes() {
      return sizes[this.size]
    }
  },
  props: {
    icon: String,
    size: {
      type: Number,
      default: 4
    }
  }
}
</script>
